import {useEffect, useState} from "react";

import Button from "../../Button/Button.tsx";

import style from "./SectionContacts.module.scss";

import {admin} from "../../../App";

import axios from 'axios';

let timeoutIdTime = '';
let timeoutIdAddress = '';
let timeoutIdNumber = '';

const SectionContacts = () => {

  const [time, setTime] = useState('Загружаем..');
  const [address, setAddress] = useState('Загружаем..');
  const [number, setNumber] = useState('Загружаем..');
  const [text, setText] =  useState('Загружаем..');

  const handleInputChangeTime = (event) => {
    setTime(event.target.value);
    clearTimeout(timeoutIdTime);
    console.log(event.target.value);
    timeoutIdTime = setTimeout(() => sendData(event.target.value, 'time'), 2000);
  };

  const handleInputChangeAddress = (event) => {
    setAddress(event.target.value);
    clearTimeout(timeoutIdAddress);
    timeoutIdAddress = setTimeout(() => sendData(event.target.value, 'address'), 2000);
  };

  const handleInputChangeNumber = (event) => {
    setNumber(event.target.value);
    clearTimeout(timeoutIdNumber);
    console.log(event.target.value);
    timeoutIdNumber = setTimeout(() => sendData(event.target.value, 'number'), 2000);
  };

  function sendData(text, block) {
    axios.post('/saveData', {
      block,
      text
    });
  }

  async function getData() {
    const {data} = await axios.get('/getData');
    setTime(data.time.text);
    setAddress(data.address.text);
    setNumber(data.number.text);
  }

  useEffect(() => {
    getData();
  }, []);

  const [fio, setFio] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className={style.wrapper}>
      <div className={style.title_accent}>
        Контакты
        <span className={style.title}></span>
      </div>
      <div className={style.block_wrapper}>
        <div className={style.block_form}>
          <div className={style.title}>
            <span className={style.title_accent}>Заявка </span>
            обратной связи
          </div>
          <div className={style.input_wrapper}>
            <div className={style.input_item}>
              <div className={style.input_text_top}>Ф.И.О.</div>
              <input
                className={style.input}
                type="text"
                placeholder="Введите фамилию, имя и отчество"
                value={fio}
                onChange={(e) => setFio(e.target.value)}
              />
            </div>
            <div className={style.input_item}>
              <div className={style.input_text_top}>Телефон</div>
              <input
                className={style.input}
                type="tel"
                placeholder="Введите номер телефона"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className={style.button_wrapper}>
            <Button stretched mode="primary" onClick={() => {
              axios.post('/sendMessage', {
                type: "2",
                fio: fio,
                phone: phone
              });
            }}>
              Отправить
            </Button>
          </div>
        </div>
        <div className={style.block_info}>
          <div className={style.block_time}>
            <div className={style.title}>
              <span className={style.title_accent}>Часы </span>работы
            </div>

            <div className={style.time_list}>
              {!admin ? (
                <>
                  {time}
                </>
              ) : (
                <textarea className={style.admin_textarea} value={time} onChange={handleInputChangeTime}>
                  {time}
                </textarea>
              )}
            </div>
          </div>
          <div className={style.block_contacts}>
            <div className={style.block_item}>
              <div className={style.title}>
                <span className={style.title_accent}>Адрес</span>
              </div>
              {!admin ? (
                <div>{address}</div>
              ) : (
                <textarea className={style.admin_textarea} value={address} onChange={handleInputChangeAddress}>
                  {address}
                </textarea>
              )}
            </div>
            <div className={style.block_item}>
              <div className={style.title}>
                <span className={style.title_accent}>Телефон</span>
              </div>
              {!admin ? (
                <div>{number}</div>
              ) : (
                <textarea className={style.admin_textarea} value={number} onChange={handleInputChangeNumber}>
                  {number}
                </textarea>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContacts;
