import { useEffect, useState } from "react";

import style from "./SectionDocuments.module.scss";

import { admin } from "../../../App";
import axios from "axios";

let timeoutId = "";

const SectionDocuments = () => {
  const [text, setText] = useState("Загружаем..");

  const handleInputChange = (event) => {
    setText(event.target.value);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => sendData(event.target.value), 2000);
  };

  function sendData(text, block = "documents") {
    axios.post("/saveData", {
      block,
      text,
    });
  }

  async function getData() {
    const { data } = await axios.get("/getData");
    setText(data.documents.text);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.title_accent}>
        <span className={style.title}>Отчётные</span> документы
      </div>
      {!admin ? (
        <div className={style.text}>
          {/* Всем клиентам мы можем предоставить отчетные документы:
        <div>- Договор</div>
        <div>
          <a
            className={style.link}
            href="https://mirkvartir89.ru/kassovyj-chek-za-prozhivanie"
            target="_blank"
          >
            - Онлайн кассовый чек
          </a>
        </div>
        <div>- Счет:</div>
        <div>
          <a
            className={style.link}
            href="https://mirkvartir89.ru/dokumenty-na-prozhivanie"
            target="_blank"
          >
            - на оплату проживания в гостинице (форма 3 г)
          </a>
        </div>
        <div>- на оплату проживания в квартире</div>
        <div>- на оплату проживания в апартаментах</div>
        <div>- Копия свидетельства регистрации ООО</div>
        <div>- Справки о проживании</div>
        <div>- Прайс Подтверждение проживания для клиентов</div>
        <div>• всех проживающих отмечаем в книге регистрационного учета</div>
        <div>• подтверждаем проживание по запросу организации</div>
        <div>
          • при необходимости, вышлем документы почтой Различные способы оплаты:
        </div>
        <div>• наличный расчет;</div>
        <div>• платежные карты: Мир;</div>
        <div>• безналичный расчет.</div> */}
          {text}
        </div>
      ) : (
        <textarea
          className={style.admin_textarea}
          value={text}
          onChange={handleInputChange}
        >
          {text}
        </textarea>
      )}
    </div>
  );
};

export default SectionDocuments;
