import {useEffect, useState} from "react";

import Button from "../../components/Button/Button.tsx";

import style from "./Documents.module.scss";

import axios from "axios";

import { admin } from "../../App.jsx";

import {isAdminLogin} from "../../App.jsx";

let timeoutIdText = '';

const Documents = () => {
  const [fio, setFio] = useState("");
  const [phone, setPhone] = useState("");
  const [fake, setFake] = useState("");
  const [isAdmin, setIsAdmin] = useState(admin);
  const [text, setText] = useState("");

  async function getData() {
    const {data} = await axios.get('/getData');
    setText(data.documentspage.text);
  }

  useEffect(() => {
    getData();
  }, []);

  const handleInputChangeText = (event) => {
    setText(event.target.value);
    clearTimeout(timeoutIdText);
    timeoutIdText = setTimeout(() => sendData(event.target.value, 'documentsPage'), 2000);
  };

  function sendData(text) {
    axios.post('/saveData', {
      block: 'documentsPage',
      text
    });
  }

  return (
    <div className={style.wrapper}>
      {!isAdminLogin ? (
        <div className={style.text} id="info">
          {text}
        </div>
      ) : (
        <textarea
          onChange={handleInputChangeText}
          className={style.admin_textarea}
          value={text}
        />
      )}
      <div className={style.block_form} id="contact">
        <div className={style.title}>
          <span className={style.title_accent}>Заявка </span>
          обратной связи
        </div>
        <div className={style.input_wrapper}>
          <div className={style.input_item}>
            <input
              style={{ display: "none" }}
              onChange={(e) => setFake(e.target.value)}
            />
            <div className={style.input_text_top}>Ф.И.О.</div>
            <input
              className={style.input}
              type="text"
              placeholder="Введите фамилию, имя и отчество"
              value={fio}
              onChange={(e) => setFio(e.target.value)}
            />
          </div>
          <div className={style.input_item}>
            <div className={style.input_text_top}>Телефон</div>
            <input
              className={style.input}
              type="tel"
              placeholder="Введите номер телефона"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className={style.button_wrapper}>
          <Button
            stretched
            mode="primary"
            onClick={() => {
              if (fake.length) return;
              axios.post("/sendMessage", {
                type: "2",
                fio: fio,
                phone: phone,
              });
            }}
          >
            Отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Documents;
