import React, { useState } from "react";
import Header from "./components/Header/Header";

import style from "./App.module.scss";

let admin = String(window.location).split("?")[1] === "morgunov";
let apiImageUrl = "https://morgunov.online";

let isAdminLogin = false;

function App() {
  return (
    <div className={style.wrapper}>
      <Header />
    </div>
  );
}

export const setAdminLogin = (value) => {
  isAdminLogin = value;
};

export default App;
export { admin };
export { apiImageUrl };
export { isAdminLogin };
