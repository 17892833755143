import SectionHome from "../../components/Sections/SectionsHome/SectionHome";
import SectionAbout from "../../components/Sections/SectionAbout/SectionAbout";
import SectionDocuments from "../../components/Sections/SectionDocuments/SectionDocuments";
import SectionContacts from "../../components/Sections/SectionContacts/SectionContacts";

import { useState } from "react";

import style from "./Home.module.scss";
import Button from "../../components/Button/Button.tsx";
import { admin } from "../../App";
import { setAdminLogin, isAdminLogin } from "../../App";

const Home = () => {
  const [isLogin, setIsLogin] = useState(false);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(admin);

  function checkLogin() {
    if (login !== "morgunov" || password !== "8491013") return false;
    return true;
  }

  return (
    <>
      {((!isLogin && !isAdmin) || (isAdminLogin))? (
        <>
          <div id="home">
            <SectionHome />
          </div>
          <div id="about">
            <SectionAbout />
          </div>
          <div id="documents">
            <SectionDocuments />
          </div>
          <div id="contacts">
            <SectionContacts />
          </div>
        </>
      ) : (
        <div className={style.auth_wrapper}>
          <div className={style.title}>Авторизация</div>
          <div className={style.form_wrapper}>
            <div className={style.input_wrapper}>
              <div className={style.input_item}>
                <div className={style.input_text_top}>Логин</div>
                <input
                  className={style.input}
                  placeholder="Логин"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>
              <div className={style.input_item}>
                <div className={style.input_text_top}>Пароль</div>
                <input
                  className={style.input}
                  placeholder="Пароль"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div>
              <Button
                stretched
                mode="primary"
                onClick={async () => {
                  if (await checkLogin()) {
                    setIsLogin(false);
                    setIsAdmin(false);
                    setAdminLogin( true);
                  }
                }}
              >
                Вход
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
